.risk-factors-item {
    max-width: 250px;
    height: 150px;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 30px;
}

.risk-factors-item--top {
    margin-top: -50px;
}

.risk-factors-item--bottom {
    margin-top: 100px;
}

.risk-factors-item__img {
    max-width: 100%;
    margin: 10px 0;
    z-index: 1;
}

.risk-factors-item__text {
    z-index: 2;
}

@media screen and (max-width: 1050px) {
    .risk-factors-item {
        max-width: 180px;
    }
}

@media screen and (max-width: 700px) {
    .risk-factors-item {
        max-height: 120px;
        font-size: 14px;
        line-height: 17px;
    }

    .risk-factors-item__img {
        max-width: 72px;
        max-height: 70px;
    }

    .risk-factors-item--top {
        margin-top: -5vw;
    }
    
    .risk-factors-item--bottom {
        margin-top: 14vw;
    }
}

@media screen and (max-width: 600px) {
    .risk-factors-item {
        max-width: 130px;
        padding-bottom: 10px;
    }

    .risk-factors-item--ru {
        max-width: 150px;
    }

    .risk-factors-item--ru > img {
        padding-left: 40px;
    }

    .risk-factors-item--bottom {
        margin-top: 13vw;
    }
}

@media screen and (max-width: 500px) {
    .risk-factors-item {
        max-width: 110px;
    }

    .risk-factors-item--sm {
        max-width: 83px;
    }

    .risk-factors-item--ru {
        max-width: 150px;
    }

    .risk-factors-item--bottom {
        order: 2;
        margin-top: 0;
    }

    .risk-factors-item--db {
        margin-top: 14vw;
    }
}

@media screen and (max-width: 450px) {
    .risk-factors-item--ru {
        max-width: 160px;
    }

    .risk-factors-item--bottom--ru {
        margin-top: 10vw;
    }
}
