.protect-heart-header-text {
    width: 90%;
    margin: auto;
    text-align: center;
    padding: 35px 20px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
    color: #B91223;
}

@media screen and (max-width: 1100px) {
    .protect-heart-header-text {
        width: 90%;
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 700px) {
    .protect-heart-header-text {
        width: 92%;
        font-size: 26px;
        line-height: 30px;
        padding-top: 0px;
    }
}
